import React from 'react'

import Layout from '../components/layout'
import HalfImage from './../components/halfImage/HalfImage'

import headerImage from './../images/header/leda-homecare-young-adult-care.png'
import leftImage from './../images/header/leda-homecare-young-person-care.png'
import rightImage from './../images/main/5.png'
import leftImage2 from './../images/main/8.png'

const OurStaff = () => (
  <Layout>
    <div className="about-us">
      <div
        className="header-image"
        style={{ backgroundImage: `url(${headerImage})` }}
      />
      <div
        className="container"
        style={{ marginTop: '100px', marginBottom: '60px' }}
      >
        <div className="row">
          <div className="twelve columns">
            <h2 className="center-text">Our Staff</h2>
            <p>
              We are where we are thanks to the efforts of our caring staff. We
              are immensely proud of the level of support they afford our
              clients and, with the help of a rigorous selection process and a
              programme of ongoing training, we do our utmost to ensure this
              quality is maintained.
            </p>
            <p>
              Currently 70 % of our team have or are working towards a minimum
              of a Level 2 Diploma in Health and Social Care. Each of our staff
              members are encouraged to attend regular training sessions which
              include:
            </p>

            <ul>
              <li>Dementia</li>
              <li>Stroke Awareness</li>
              <li>Falls Safety</li>
              <li>Diet and Nutrition</li>
            </ul>
            <p>
              We go to great lengths to ensure that all our carer’s are
              qualified, competent and supported. We also feel that it is
              important for our office staff and management to have the same
              caring qualities. So no matter whether you are calling for
              information, advice or to enquire about care, you will find that
              your needs come first in our organisation.
            </p>
          </div>
        </div>
      </div>
      <HalfImage
        image={leftImage}
        imagePosition="left"
        background
        text={[
          <h2 key="0">The Interview</h2>,
          <p key="1">
            Each candidate is interviewed by a member of our management team to
            ensure that they have not only the experience but also the aptitude
            for the job. It’s important to Leda Homecare that we only take on
            care workers with naturally friendly, respectful and warm
            personalities because we know that they will be representing us in
            your home.
          </p>,
        ]}
      />
      <HalfImage
        image={rightImage}
        imagePosition="right"
        mobileFlip
        background
        text={[
          <h2 key="0">Background Checks</h2>,
          <p key="1">
            Doing your best for your family comes as second nature, but there
            are times when an extra pair of hands would be very welcome. Our
            respite care is as flexible as you need it to be. We can offer
            holiday cover or just a few hours, we will work with you to create a
            care package which works for both you and the person you are caring
            for.
          </p>,
        ]}
      />
      <HalfImage
        image={leftImage2}
        imagePosition="left"
        background
        text={[
          <h2 key="0">Qualifications </h2>,
          <p key="1">
            Mandatory training takes place throughout the induction process and
            includes introductions to Health and Safety, Infection Control and
            Moving and Handling Practices. Each new staff member is supervised
            by an experienced staff member for a number of days out in the
            community to ensure that the practical application of Leda’s
            procedures are being followed and that each new carer is supported
            and comfortable in their role.
          </p>,
        ]}
      />
    </div>
    <div className="container">
      <div className="row">
        <div className="twelve columns">
          <h2 className="center-text">Staff Testimonials</h2>
          <h3 className="center-text">Donna</h3>
          <p>
            After working in a shop for 10 years I never felt appreciated or
            that I made a difference to peoples life. I was looking for a job
            were I made a difference so I looked into care and decided to go in
            to Community care I started working for Leda care 2 years ago since
            then I feel like I have made such a difference for just making a cup
            of tea for a lady who has no family the smile on her face when I
            arrive in a morning is so heart warming she tells me story’s about
            her when she was young and the light in her eyes is amazing to the
            point you never want to leave.
          </p>
          <p>
            I love making a difference to our clients lives helping them stay
            living independently in their homes were they are at there happiest
            and in a place were they have wonderful memories and feel safe.{' '}
          </p>
          <p>
            I have a great relationship with them while I am doing personal care
            or their washing or ironing we are laughing and chatting away they
            tell me stories from years ago and I get as much pleasure from
            listening to them as much as they get reliving the good times.
          </p>
          <p>
            As careers we are there at the most vulnerable times of their life
            so for them to have a warm smiling face coming through the door
            letting them know that some one does care and are looking after
            their best interest ; just taking a bun for them can mean the world
            to them.
          </p>
          <p>
            Making sure that every morning they are clean and smart we look
            after all walks of life so some of our male clients still like a
            ironed shift and trousers and a tie on even though they are sat in a
            chair all day. We always respect that’s how they like to be and I
            love to see how they thrive by them staying in their own homes.
          </p>
          <p>
            A care workers job is never the same from day to day and you learn
            thing everyday plus we have great opportunities to do more training
            and keep up to date with new equipment that comes out to help our
            clients to live Independently.
          </p>
          <p>
            Even at the end of their journey you can have satisfaction that they
            have spent their last few week feeling that they had someone who did
            care and that just listened to them and that they did matter and
            that they was just as important as anyone else.
          </p>
          <p>
            That’s why I love being a care worker and always go to work with a
            smile and come home feeling blessed to meet such amazing people that
            sometimes people forget ,in fact I use the word loosely work as it
            never feels like work
          </p>
          <h3 className="center-text">Megan</h3>
          <p>
            My name is Megan and I have been a Leda Homecare employee for 7
            years now. Every evening I leave work knowing I have helped to keep
            an individual in their own home comforts and helped towards
            maintaining their health. This is the most rewarding and
            heartwarming feeling and even though the hours are long and the
            mornings are very early starts, to see my service users comfortable,
            happy and content to still be at home makes it all worth while.{' '}
          </p>
          <p>
            My work involves a number of roles from getting a service user up
            and out of bed, ready for the day, carrying out their personal care,
            cooking them breakfast, dinner, tea and supper and then putting them
            back to bed at night ensuring they are safe and comfortable. To
            administering medication, taking blood sugar readings, applying
            creams, changing cathiter bags, emptying commodes,the list goes on.
            To simple little things like making them cups of tea in their
            favourite cups, to adjusting the cushions in their armchairs so they
            are extra comfortable and can see the television, to sitting and
            having a chat about the weather and for offering them emotional,
            meantal and physical support. However these little things are the
            most important as that is what makes them still dignified and happy
            in their own home.
          </p>
          <p>
            I wouldn’t change my job for the world as every day I know I am
            helping not only one but a number of people to stay happy, healthy,
            as independent as can be and most importantly at home where everyone
            likes to be.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default OurStaff
